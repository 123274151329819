/* ResetPassword.css */

.reset-password-box {
    width: 100%;
    max-width: 400px;
    margin: 50px auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    font-family: 'Arial', sans-serif;
}

.reset-password-box h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333333;
}

.reset-password-box .error {
    color: #ff4d4d;
    font-size: 14px;
    margin-bottom: 15px;
    text-align: left;
}

.reset-password-box form {
    display: flex;
    flex-direction: column;
}

.reset-password-box input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

.reset-password-box input:focus {
    border-color: #145c49;
    outline: none;
}

.reset-password-box button {
    background-color: #145c49;
    color: #ffdd8f;
    border: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.reset-password-box button:hover {
    background-color: #145c49;
}

.reset-password-box button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.reset-password-box p {
    font-size: 14px;
    color: #555555;
    margin-top: 10px;
}
